<template>
  <section>
    <b-card>
      <b-row>
        <b-col sm="12">
          <b-media>
            <template #aside>
              <b-avatar
                variant="primary"
                :text="userInitials(profile)"
                :src="userProfileImage"
                size="7rem"
              />
            </template>

            <h6>
              <b-badge :variant="STATUS_COLOR[userOverviewStatus]">
                {{ userOverviewStatus }}
              </b-badge>
            </h6>
        
            <h4 class="mt-1 font-weight-500">
              {{ profile.full_name }}
            </h4>
            <b-row class="ml-0 mt-1">
              <p class="mr-1">
                <feather-icon
                  icon="MailIcon"
                  size="16"
                />
                {{ profile.email }}
              </p>
              <p class="mr-1">
                <feather-icon
                  icon="PhoneIcon"
                  size="16"
                />
                Phone: {{ profile.phone }}
              </p>
              <p class="mr-1">
                <feather-icon
                  icon="CalendarIcon"
                  size="16"
                />
                Joined: {{ localeDateStringFromIsoDateTime(profile.created_at) }}
              </p>
            </b-row>  
          </b-media>
        </b-col>
      </b-row>
    </b-card>

    <b-row class="mt-2">
      <b-col sm="6">
        <b-tabs
          v-model="tabIndex"
          pills
          content-class="tab-custom mt-3"
          nav-wrapper-class="nav-top"
          @input="clear"
        >
          <b-tab
            v-if="hasMenteeRole"
            lazy
          >
            <template #title>
              <feather-icon
                icon="UsersIcon"
                size="16"
              />
              <span class="font-weight-bold">Suggested mentors</span>
            </template>
            <b-card>
              <suggestion-list
                ref="suggestionList"
                :user="profile"
                :role-id="userRoles.MENTEE"
                @view="showUserDetail"
              />
            </b-card>
          </b-tab>
          <b-tab
            lazy
          >
            <template #title>
              <feather-icon
                icon="StarIcon"
                size="16"
              />
              <span class="font-weight-bold">Shortlisted</span>
            </template>
            <b-card>
              <short-list-users
                ref="shortList"
                :user="profile"
                :role-id="userRoles.MENTEE"
                @view="showUserDetail"
              />
            </b-card>
          </b-tab>
          <b-tab
            lazy
          >
            <template #title>
              <feather-icon
                icon="CopyIcon"
                size="16"
              />
              <span class="font-weight-bold">History</span>
            </template>
            <b-card class="history-users">
              <history-users
                ref="historyList"
                :user="profile"
                :role-id="userRoles.MENTEE"
                @view="showUserDetail"
                @isLoadingUser="updateFetchUser"
              />
            </b-card>
          </b-tab>
        </b-tabs>
      </b-col>

      <b-col sm="6">
        <div style="height: 80px"></div>
        <user-detail
          :user="user"
          :pending-requests-count="pendingRequestsCount"
          :role-id="userRoles.MENTEE"
          :is-fetching-user="isFetchingUser"
          @shortlist-added="refreshLists"
          @shortlist-removed="refreshLists"
          @match-request-sent="refreshLists"
          @match-request-accepted="refreshLists"
          @match-request-withdrawn="refreshLists"
          @match-request-declined="refreshLists"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {modService} from '@services';
import { BCard, BTabs, BTab, BRow, BCol, BAvatar, BMedia, BBadge } from "bootstrap-vue";
import { mapGetters } from "vuex";
import { userRoles, questionTypes, matchRequestStatus, userStatus, userStatusDisplay } from '@models';
import ShortListUsers from "./partials/ShortListUsers.vue";
import HistoryUsers from "./partials/HistoryUsers.vue";
import UserDetail from "./partials/UserDetail.vue";
import SuggestionList from "./partials/SuggestionList.vue";
import { localeDateStringFromIsoDateTime, STATUS_COLOR } from "@/libs/utils";

export default {
  name: "FindMatchSuggestions",
  components: {
    BCard, 
    BTabs,
    BTab,
    BRow, 
    BCol, 
    BAvatar,
    ShortListUsers,
    UserDetail, 
    HistoryUsers,
    SuggestionList,
    BMedia,
    BBadge
  },
  data() {
    return {
      localeDateStringFromIsoDateTime,
      user: {},
      navItems:[
        {title: 'Suggestions', pathName: 'find-a-match-suggestions', isActive: true},
        {title: 'Shortlist', pathName: 'find-a-match-shortlist', isActive: false},
        {title: 'History', pathName: 'find-a-match-history', isActive: false}
      ],
      shortList: [],
      pendingRequestsCount: undefined,
      isFetchingUser: false,
      tabIndex: 0
    };
  },
  computed: {
    ...mapGetters("profile", ["profile"]),
    ...mapGetters("app", ["currentProgram"]),

    userOverviewStatus() {
      const filteredRoles = this.profile.user_roles;
      const programRole = filteredRoles.find(r => Number(r.program_id)=== Number(this.$route.params.id || this.currentProgram.id));
      return userStatusDisplay[programRole ? programRole.status_id : userStatus.INACTIVE];
    },
    hasMenteeRole() {
      return this.profile.roles.some(role => role === userRoles.MENTEE);
    },
    userProfileImage() {
      return this.profile.profile_image?.url || '';
    },
  },
  created () {
    this.findPendingMatchCount();
  },
  methods: {
    updateFetchUser(state) {
      this.isFetchingUser = state;
    },
    showUserDetail (user) {
      this.user = user;
    },
    clear() {
      this.user = {};
    },
    userInitials(user) {
      if(user && user.first_name) {
        const firstInt = user? Array.from(user?.first_name)[0]: '';
        const lastInt = user? Array.from(user?.last_name)[0]: '';
        return `${firstInt}${lastInt}`;
      }
      return '';
    },
    async refreshLists() {
      await this.findPendingMatchCount();
      if(this.$refs.suggestionList) {
        this.$refs.suggestionList.getSuggestionList();
      }
      if(this.$refs.shortList) {
        this.$refs.shortList.getShortList();
      }
      if(this.$refs.historyList) {
        this.$refs.historyList.getPendingRequests();
      }
    },
    async findPendingMatchCount() {
      try {
        const programId = this.currentProgram?.id;
        const roleId = userRoles.MENTEE;
        const response = await modService.listMatchRequests(programId, roleId);
        const { data } = response;
        this.pendingRequestsCount = data?.items.filter(i => i.status_id === matchRequestStatus.PENDING).length;
      } catch (e) {
        console.log(e);
      }
    }

  },
  setup() {
    return {
      STATUS_COLOR,
      questionTypes, 
      userRoles
    };
  }
};
</script>

<style lang="scss" scoped>
.suggested-user-list {
  cursor: pointer;
}
.selected {
  background: #f8f8f8;
  border-left: 5px solid var(--aom-color-secondary);
}
.list-style-none {
  list-style: none;
}

.tab-custom {
  .tab-pane {
    padding: 0;
  }
}

.history-users {
  background-color: #E7E9F6;

  .card-body {
    padding: 0;
  }
}
</style>